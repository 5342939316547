<template>
  <v-layout
    v-if="templates"
    wrap 
  >
    <v-flex xs8
      style="padding: 20px;"
    >
      <div
        style="padding: 10px; background-color: white"
      >
    <v-data-table
      :headers="headers.filter(x => x.value !== 'WorkspaceName' || items.filter(x => !x.WorkspaceID).length > 0)"
      :items="items"
      :items-per-page="-1"
      class="elevation-0"
      hide-default-footer
    >      
      <template 
        v-slot:top 
      >
        <v-layout wrap>
          <v-flex
            xs="6"
          >
            <h1><v-icon large color="black">mdi-presentation-play</v-icon> {{$t('players.presentations', locale)}}</h1>
          </v-flex>
          <v-flex
            xs="6"
          >
            <v-layout wrap>
              <v-flex xs6 style="padding-left:10px;">
                <search :onSearch="handleSearch" :showClear="searchValue && searchValue.length > 0 ? true : false" :maxWidth="400" />
              </v-flex>
              <v-flex xs6 style="text-align: right;">
                <v-btn 
                  @click="handleGetData(null)"
                  block
                >
                  {{ $t('common.refresh', locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
            <!--div
              style="text-align: right; padding: 10px;width: 100%;"
            >
              <v-btn 
                @click="handleGetData"
              >
                {{ $t('common.refresh', locale) }}
              </v-btn>
            </div-->
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:item.ident="{ item }">
        <a @click="editedID = item.ID">{{item.Name ? item.Name : item.ID }}</a>
      </template>
      <template v-slot:item.template="{ item }">
        {{ templates.filter(x => x.ID===item.TemplateID).map(item => item.Name).shift() }}
      </template>
      <template v-slot:item.Players="{ item }">
        <template-players
          :presentationID="item.ID"
          :workspaceID="workspaceID"
          :onClick="handleEditPlayer"
        />
        <!--a @click="editedID = item.ID">{{item.Name ? item.Name : item.ID }}</a-->
      </template>
    </v-data-table>
    <pagination
      v-if="count != null"
      :startIndex="startIndex"
      :count="count"
      :itemsPerPage="itemsPerPage"
      :onGetData="handleGetData"
      :locale="locale"
      :onChangeItemsPerPage="handleChangeItemsPerPage"
      :arrayPages="[5, 10, 15, 20, 25, 30, 50]"
    />
    <!--player-form /-->
      </div>
    </v-flex>
    <v-flex xs4 
      v-if="!editedPlayerID"
      style="padding: 20px 20px 0 0 ;" 
    >
      <div
        style="padding: 10px;background-color:white;min-height: calc(100vh);"
      >
      <v-btn 
        v-if="!editedID"
        @click="handleAddItem"
        block
        color="primary"
      >
      {{ $t('common.add', locale) }}
      </v-btn>
      <presentation-form
        v-if="editedID"
        :workspaceID="workspaceID"
        :editedID="editedID"
        :onClose="handleClose"
        :locale="locale"
        :templates="templates"
      />
      </div>
    </v-flex>
    <v-flex xs4 
      v-else
      style="padding: 20px 20px 0 0 ;" 
    >
      <div
        style="padding: 10px;background-color:white;min-height: calc(100vh);"
      >
      <android-form
        :workspaceID="workspaceID"
        :editedID="editedPlayerID"
        :onClose="handleClose"
        :locale="locale"
      />
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import sec from '@/services/sec'
import Search from '@/components/Search'
import Pagination from '@/components/customTable/Pagination'
import AndroidForm from '../android/AndroidForm'
import PresentationForm from './PresentationForm'
import TemplatePlayers from './TemplatePlayers'
export default {
  components: {
    Search,
    Pagination,
    PresentationForm,
    TemplatePlayers,
    AndroidForm
  },
  data: () => ({
    count: null,
    startIndex: 0,
    itemsPerPage: 15,
    templates: null,
    items: [],
    editedID: null,
    mainWidth: null,
    editedPlayerID: null,
    searchValue: '',
    allowEditPlayer: false,
  }),
  computed: {
    ...mapState('app',['locale', 'playersWorkspace']),
    workspaceID () {
      return this.playersWorkspace
    },
    environment () {
      return 'players'
    },
    headers () {
      return [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'ident',
        },
        { text: 'Cliente', sortable: false, value: 'WorkspaceName' },
        {
          text: 'Template',
          align: 'start',
          sortable: false,
          value: 'template',
        },
        { text: 'Players', align: 'center', sortable: false, value: 'Players' },
      ]
    },
  },
  watch: {
    playersWorkspace () {
      this.handleGetData()
    },
  },
  mounted () {
    const aux = localStorage.getItem('presentationItemsPerPage')
    if (aux && aux !== this.itemsPerPage) this.itemsPerPage = aux && aux.length > 0 ? aux | 0 : 15
    this.handleGetTemplates()
    this.handleGetData()
  },
  methods: {
    getDate (v) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

      return v.toLocaleDateString('es', options)
    },
    handleGetTemplates () {
      const url = 'v1/private/templates'
      api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          this.templates = response
          /*
          this.items = response.map(item => {
            item.ident = {
              ID: item.ID,
              Name: item.Name,
            }
            return item
          })
          */
        })
    },
    handleSearch (v) {
      this.searchValue = v
      this.handleGetData()
    },
    handleChangeItemsPerPage (v) {
      this.startIndex = 0
      this.itemsPerPage = v
      localStorage.setItem('presentationItemsPerPage', v)
      this.handleGetData()
    },
    async handleGetData (v) {
      this.allowEditPlayer = await sec.checkPermission('players', this.workspaceID, '31c38af2-3395-40f2-b1cc-3232c87c3cad')
      this.handleClose()
      this.count = 0
      this.items = []
      if (v !== null && v != undefined) this.startIndex = v
      if (!this.workspaceID) return
      //const url = 'v1/private/presentations/'
      const url = `v1/private/workspaces/${this.workspaceID}/presentations/`
      //api.getAllWithoutLimit (this.environment, url)
      api.getAll (this.environment, url, this.startIndex, this.itemsPerPage, this.searchValue)
        .then(response => {
          this.count = response.count
          this.items = response.data.map(item => {
            item.ident = {
              ID: item.ID,
              Name: item.Name,
            }
            return item
          })
        })
    },
    handleAddItem () {
      this.editedID = 'new'
    },
    handleClose (refresh) {
      this.editedID = null
      this.editedPlayerID = null
      if (refresh) this.handleGetData()
    },
    handleEditPlayer (v) {
      if (!this.allowEditPlayer) return
      this.editedPlayerID = v
    },
  },
}
</script>

