<template>
  <div
    style="position:absolute;"
    :style="{
      backgroundColor: backgroundColor,
      color: color,
      fontSize: fontSize,
      top: top,
      right: right,
      width: width,
      height: height
    }"
  >
    <custom-carousel 
      v-if="contents"
      :contents="contents"
    />
  </div>
</template>
<script>
import { parseString } from 'xml2js'
import CustomCarousel from './CustomCarousel'
export default {
  components: {
    CustomCarousel,
  },
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
    top: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    originalWidth: {
      type: String,
      required: true,
    },
    originalHeight: {
      type: String,
      required: true,
    },
    xmls: {
      type: Array,
      required: true,
    },
    zone: {
      type: Object,
      requried: true,
    },
    scaleFactor: {
      type: Number,
      required: true,
    },
    speedFactor: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    contents: [],
  }),
  watch: {
    xml () {
      this.handleGetItems()
    },
    speedFactor () {
      this.handleGetItems()
    },
  },
  mounted () {
    this.handleGetItems()
  },
  methods: {
    prepareMessage (v, duration) {
      return `<div class="message-preview"><span class="p-preview" style="animation-duration: ${duration}s;font-size:${this.fontSize};color:${this.color};">${v}</span></div>`
    },
    prepareDurationWidhtSpeedFactor (v) {
      const aux = v / this.speedFactor
      return aux < 1 ? 1 : aux | 0
    },
    prepareMediaContent (type, url, description, duration) {
      if (!duration || duration.length === 0) duration = 1
      const content = type === 'message'? this.prepareMessage(description, duration) : type === 'html' ? `<iframe class="frame" src="${url}" style="border:0;width:${this.originalWidth};height:${this.originalHeight};-ms-zoom: ${this.scaleFactor};-moz-transform: scale(${this.scaleFactor});-moz-transform-origin: 0 0;-o-transform: scale(${this.scaleFactor});-o-transform-origin: 0 0;-webkit-transform: scale(${this.scaleFactor});-webkit-transform-origin: 0 0;" />`
        : type === 'mp4' ? `<video src="${url}" width="100%" height="100%" loop
            autoplay="autoplay" muted defaultmuted playsinline />`
        : `<img src="${url}" width="100%" height="100%">`
      return {
        content,
        duration: this.prepareDurationWidhtSpeedFactor(duration),
        type
      }
    },
    async handleGetItems (){
      try {
        if (!this.xmls || this.xmls.length === 0) return
        const contents = []
        for (const xml of this.xmls) {
          if (xml.indexOf('.xml') < 0) {
            const params = xml.split('https')
            if (params.length === 2) {
              contents.push(this.prepareMediaContent ('html', `https${params[1]}`, null, params[0]))
            } else {
              contents.push(this.prepareMediaContent ('html', `https${params[0]}`, null, 10))
            }
            continue
          }
          /*
          //const data = await new Promise((resolve, reject) => axios.get(xml, { headers: {"Authorization" : `Bearer ${tokenStr}`} })
          const data = await new Promise((resolve, reject) => axios.get(xml, { headers: {} })
            .then (response => {
              resolve(response.data)
            })
            .catch (error => {
             reject(error)
            }))*/
          const data = await new Promise((resolve) => fetch(xml)
            .then(response => response.text())
            .then(data => resolve(data))
          )
          const results = await new Promise((resolve, reject) => parseString(data, (err, results) => {
            if (err) reject(err)
            else resolve(results)
          }))

          if (!results || !results.rss || !results.rss.channel) continue
          const channel = results.rss.channel.shift()
          if (!channel || !channel.item) continue
          contents.push(...channel.item.map(item => {
            if (item['media:content']) {
              const { url, type, description, duration } = item['media:content'].shift()['$']
              return this.prepareMediaContent(type, url, description, duration)
            } else if (item.description) {
              let time = ((item.description.length /200) | 0)
              time = (time ? time : 1) * 25
              return this.prepareMediaContent('message', null, item.description, time)
            }
          }))
        }
        this.contents = contents
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

