import api from '@/services/api'
import utils from '@/services/utils'

export default  {
  async checkPermission (environment, workspaceID, v) {
    try {
      const url = environment === 'status2' || environment === 'status' ? `v1/internal/constants/sidebar/${environment}`
                : environment === 'system'
                  ? `v1/internal/users/${utils.getUser()}/sidebar`
                  : `v1/internal/workspaces/${workspaceID}/sidebar`

      return await new Promise((resolve, reject) => api.getAllWithoutLimit (environment, url)
        .then(response => {
          resolve(response.filter(x => x.id===v).length > 0 ? true : false)
        }).catch (error => {
          reject(error)
        }))
    } catch (error) {
      console.log(error)
      return false
    }
  },
}
