<template>
  <v-layout wrap v-if="editedItem">
    <h2 style="margin-bottom: 20px;"><v-icon color="black" style="margin-right: 10px;">mdi-presentation-play</v-icon>{{ editedID === 'new' ? $t('common.add', locale) : $t('common.edit', locale) }} {{$t('players.presentation', locale).toLowerCase()}}</h2>
    <!--v-flex xs12 v-if="!editedItem.ID">
      <qrcode-stream v-if="isMobile" @decode="onDecode" />
      <v-text-field
        v-model="editedItem.ID"
        label="ID"
        outlined
      />
    </v-flex-->
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Name"
        label="Nombre"
        outlined
        dense
      />
    </v-flex>
    <v-flex xs12>
      <v-autocomplete
        v-model="editedItem.WorkspaceID"
        :items="workspaces"
        outlined
        dense
        item-text="Name"
        item-value="ID"
        :label="$t('players.client', locale)"
      />
    </v-flex>
    <v-flex xs12 v-if="fromPlayerList" style="padding-bottom:10px;">
      <small> Players: </small>
      <template-players
        :small="true"
        :presentationID="editedItem.ID"
        :workspaceID="workspaceID"
        :onClick="() => {}"
      />
    </v-flex>
    <v-flex xs12 style="padding-bottom:10px;">
      <color-picker
        label="Color general"
        :value="sMainBackgroundColor"
        :onChange="(v) => handleChangeColor(v)"
        borderRadius="20px 50 20 0"
      />
    </v-flex>
    <v-flex xs12>
      <small>{{ $t('players.template', locale) }}</small>
      <div style="padding: 15px 10px 25px 10px">
        <template-menu
          :name="getTemplateName(editedItem.TemplateID)"
          :templates="templates"
          :onClick="(v) => { editedItem.TemplateID = v}"
          :locale="locale"
          :active="editedItem.TemplateID"
        />
      </div>
      <!--v-radio-group v-model="editedItem.TemplateID">
        <v-radio
          v-for="n in templates"
          :key="n.ID"
          :label="`${n.Name}`"
          :value="n.ID"
        />
      </v-radio-group-->
      <!--template-struct 
        :template="templates.filter(x => x.ID === editedItem.TemplateID).shift()"
      /-->
    </v-flex>
    <v-flex xs12>
      <zone-form
        :presentation="editedItem"
        :templates="templates"
        :onChange="handleChangeZones"
        :locale="locale"
      />
    </v-flex>
    <v-flex xs12 style="padding-bottom:10px;">
      <v-checkbox
        v-model="editedItem.PreviewVertical"
        :label="$t('players.previewVertical', locale)"
        hideDetails
      />
    </v-flex>
    <v-flex xs12>
      <div style="padding: 15px 10px 25px 10px">
        <preview-menu
          :templates="templates"
          :locale="locale"
          :templateID="editedItem.TemplateID"
          :bgColor="this.editedItem.MainBackgroundColor"
          :zones="editedItem.Zones"
          :isVertical="editedItem.PreviewVertical ? true : false"
        />
      </div>
    </v-flex>
    <v-flex xs12>
      <action-buttons
        v-if="editedItem"
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
const defaultItem = {
  ID: null,
  Name: '',
  TemplateID: '140181d0-aeff-11ed-9c29-f933aac7d201',
  Zones: [],
  MainBackgroundColor: '000000',
}
import api from '@/services/api'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
import ColorPicker from '@/components/common-components/ColorPickerItem'
import TemplateMenu from './TemplateMenu'
import PreviewMenu from './previewMenu/Index'
import TemplatePlayers from '../presentations/TemplatePlayers'
//import TemplateStruct from './TemplateStruct'
import ZoneForm from './ZoneForm'
export default {
  name: 'PresentationForm',
  components: {
    ActionButtons, 
    ZoneForm,
    ColorPicker,
    TemplateMenu,
    TemplatePlayers,
    PreviewMenu,
  //  TemplateStruct
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    templates: {
      type: Array,
      required: true,
    },
    editedID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onClose: {
      type: Function,
      required: true,
    },
    fromPlayerList: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editedItem: null,
    sMainBackgroundColor: '#000000',
    workspaces: [],
  }),
  computed: {
    isMobile () {
      return utils.isMobile()
    },
    environment () {
      return 'players'
    },
    /*
    templates () {
      return [
        {
          id: '140181d0-aeff-11ed-9c29-f933aac7d201',
          name: 'Mantenimiento',
          zones: 0,
        },
        {
          id: '1a458ce1-2349-4ee8-bb5e-3376eccbcfb8',
          name: 'Pantalla completa',
          zones: 1
        },
        {
          id: '3ffa6664-2bc5-4e7d-8a71-914a96ad5c74',
          name: '3 zonas',
          zones: 3,
        },
        {
          id: '2d5dea85-cd7c-46ba-91ac-ade5228c526a',
          name: '3 zonas_2',
          zones: 3,
        }
      ]
    },*/
  },
  watch: {
    'editedID' () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetWorkspaces()
    this.handleGetData()
  },
  methods: {
    getTemplateName (v) {
      return this.templates.filter(x => x.ID === v).map(item => item.Name).shift()
    },
    handleGetWorkspaces () {
      api.getAllWithoutLimit (this.environment, `v1/internal/users/${utils.getUser()}/workspaces`)
        .then(response => {
          this.workspaces = response
        })
    },
    handleGetData () {
      if (!this.editedID) {
        this.editedItem = null
        return
      }
      if (this.editedID === 'new') {
        this.editedItem = JSON.parse(JSON.stringify(defaultItem))
        this.editedItem.WorkspaceID = this.workspaceID
        this.sMainBackgroundColor = `#${this.editedItem.MainBackgroundColor && this.editedItem.MainBackgroundColor ? this.editedItem.MainBackgroundColor : 'FFFFFF'}`
      } else {
        const url = 'v1/private/presentations/'
        api.getItem (this.environment, url, this.editedID)
          .then(response => {
            if (!response) return
            this.editedItem = JSON.parse(JSON.stringify(response))
            this.sMainBackgroundColor = `#${this.editedItem.MainBackgroundColor && this.editedItem.MainBackgroundColor ? this.editedItem.MainBackgroundColor : 'FFFFFF'}` 

          })
      }
    },
    prepareData () {
      this.item = JSON.parse(JSON.stringify(this.editedItem))
    },
    onDecode (v) {
      this.item.ID = v
    },
    handleChangeZones (v) {
      this.editedItem.Zones = v
    },
    handleSave () {
      if (!this.editedItem.ID) {
        // insert
        const url = 'v1/private/presentations/'
        api.addItem (this.environment, url, this.editedItem)
          .then(() => {
            this.onClose(true)
          })
      } else {
        //update
        const url = 'v1/private/presentations/'
        api.updateItem (this.environment, url, this.editedItem.ID, this.editedItem)
          .then(() => {
            this.onClose(true)
          })
      }
    },
    handleDelete () {
      const url = 'v1/private/presentations/'
      api.deleteItem (this.environment, url, this.editedItem.ID)
        .then(() => {
          this.onClose(true)
        })
    },
    handleChangeColor (v) {
      this.editedItem.MainBackgroundColor = v.replace('#','')
      //this.handleChange()
    },
  },
}
</script>
