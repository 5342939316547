<template>
  <div>
    <v-chip
      class="ma-2"
      label
      v-for="item of players"
      :key="item.ID"
      @click="onClick(item.ID)"
      :color="item.WorkspaceID !== workspaceID ? 'red' : null"
      :xSmall="small"
    >
      {{item.Name}} {{ item.WorkspaceID !== workspaceID ? ` [${item.WorkspaceName}]` : '' }}
    </v-chip>
  </div>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    presentationID: {
      type: String,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      requried: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    players: null,
  }),
  computed: {
    environment () {
      return 'players'
    },
  },
  mounted () {
    this.handleGetPlayers()
  },
  methods: {
    handleGetPlayers () {
      this.players = null,
      this.loading = true
      const url = `v1/private/presentations/${this.presentationID}/players/`
      api.getAllWithoutLimit (this.environment, url)
        .then(response => {
          this.players = response
          this.loading = false
        })
        .catch (error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>

